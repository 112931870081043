.dropdown {
    display: inline-block;
}

.dropdown > button,
.dropdown > button:hover,
.dropdown > button:active,
.dropdown > button:focus-visible,
.dropdown > button.btn.show {
    min-width: 185px;
    min-height: 30px;
    padding: 1px 2px;
    font-size: 16px;
    color: black;
    border: 1px #767676 solid;
    border-radius: 3px;
    background-color: unset;
}

.dropdown > button.btn:disabled {
    background-color: rgba(59, 59, 59, 0.3);
    color: rgb(170, 170, 170);
    border-color: rgba(118, 118, 118, 0.3);
}

.dropdown > div {
    min-width: 185px;
}

input.input-number {
    width: 100px;
}

.App .main-nav {
    width: 100%;
}

.App .main-nav > h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    padding: 10px 0;
    margin: unset;
}

.App .main-nav > h2::before,
.App .main-nav > h2::after {
    display: inline-block;
    margin: 0 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.App .main-nav .list-ctn {
    overflow: hidden;
    position: absolute;
    z-index: 5;
    background-color: rgba(30, 100, 255, 0.9);
    width: 100%;
}

.App .main-nav .list-ctn .list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: -100%;
    transition: all 0.5s;
}

.App .main-nav .list-ctn .list.expand {
    margin-top: 0;
}

.App .main-nav .list-ctn .list > * {
    color: white;
    font-weight: bold;
    width: calc(100% / 4);
    padding: 10px;
    display: flex;
    justify-content: center;
}

.App .main-nav .list-ctn .list > .logout-btn {
    width: 100%;
    cursor: pointer;
}
