.table-row.approved > td {
    background-color: #96CAE6;
}

.new-edit-modal .sub-questions > div,
.new-edit-modal .sub-questions > table {
    padding-left: 25px;
}

.new-edit-modal .sub-questions > table {
    display: inline-block;
}

/* override bootstrap modal width */
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 700px;
    }
}
