.page-body .btn-download {
    text-decoration: underline;
    cursor: pointer;
}

.new-edit-modal .sub-questions > div,
.new-edit-modal .sub-questions > table {
    padding-left: 25px;
}

.new-edit-modal .sub-questions > table {
    display: inline-block;
}

.new-edit-modal .sub-questions .btn-clear {
    cursor: pointer;
}

.new-edit-modal .tax-ctn > input + input {
    margin-left: 5px;
}

/* override bootstrap modal width */
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 700px;
    }
}
